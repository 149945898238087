<template>
  <div>
    <v-row v-if="model.location === null">
      <v-col class="d-flex">
        <v-spacer />
        <v-chip
          color="warning"
          class="mr-2"
          small
        >
          {{ $t('messages.views.admin.components.configuration.stations.window.trafficLightWindow.noPositionForTrafficLight') }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <o-text-field
          v-model="model.name"
          :rules="trafficLightValidation.name"
          label="labels.trafficLight.name"
          prepend-icon="mdi-rename-box"
          @change="onNameChanged"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-numeric</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('labels.trafficLight.number') }} : {{ model.number }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-tooltip bottom>
            <template v-slot:activator="props">
              <v-list-item
                v-bind="props.attrs"
                v-on="props.on"
              >
                <v-list-item-icon>
                  <v-icon>mdi-shape</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('labels.trafficLight.type') }} : {{ model.type }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            {{ getTrafficLightTypeTranslated(model.type) }}
          </v-tooltip>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-subheader class="red--text">
          <v-icon
            class="mr-2"
            color="red"
          >
            mdi-traffic-light
          </v-icon>
          {{ $t('messages.views.admin.components.configuration.stations.window.trafficLightWindow.antagonisms') }}
        </v-subheader>
        <v-list v-if="computedAntagonisms.length">
          <v-list-item
            v-for="(antagonism, i) in computedAntagonisms"
            :key="`antagonism-${i}`"
            @click="() => onAntagonismClick(antagonism)"
          >
            <v-list-item-icon>
              {{ antagonism.trafficLight.number }}
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('labels.antagonism.clearanceTime') + ' : ' + antagonism.clearanceTime + 's' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <p
          v-else
          class="ml-5 text--secondary font-italic"
        >
          {{ $t('messages.views.admin.components.configuration.stations.window.trafficLightWindow.noAntagonism') }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { getTrafficLightTypeTranslationKey } from '@utils/trafficLight';

  export default {
    props: {
      value: {
        type: Object,
        required: true,
      },
      trafficLights: {
        type: Array,
        required: true,
      },
      antagonisms: {
        type: Array,
        required: true,
      },
    },

    data: function () {
      return {
        trafficLightValidation: require('@/validation/entities/trafficLight.json'),
      };
    },

    computed: {
      model: {
        get () { return this.value; },
        set (model) {
          this.$emit('input', model);
        },
      },
      computedAntagonisms () {
        const items = [];

        for (const antagonism of this.antagonisms) {
          if (antagonism.trafficLightA === this.model.id) {
            items.push({
              trafficLight: this.getTrafficLightFromId(antagonism.trafficLightB),
              clearanceTime: antagonism.clearanceTime,
            });
          // } else if (antagonism.trafficLightB.id === this.trafficLight.id) {
          //   items.push({
          //     trafficLight: antagonism.trafficLightA,
          //     clearanceTime: antagonism.clearanceTime,
          //   });
          }
        }

        return items;
      },
    },

    methods: {
      getTrafficLightTypeTranslated (type) {
        return this.$t(getTrafficLightTypeTranslationKey(type));
      },
      getTrafficLightFromId (id) {
        return this.trafficLights.find((trafficLight) => trafficLight.id === id);
      },
      onAntagonismClick (antagonism) {
        this.$emit('traffic-light-click', antagonism.trafficLight);
      },
      onNameChanged () {
        this.update();
      },
      update () {
        this.$apollo.mutate({
          mutation: require('@gql/mutations/trafficLight/updateTrafficLight.gql'),
          client: 'stations',
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              id: this.model.id,
              name: this.model.name,
            },
          },
        }).catch((e) => {
          this.$flash(null, 'error');
        });
      },
    },
  };
</script>
